import axios from "axios";

const host_name = window.location.hostname
let domain = null
let type = null

if (host_name.includes('bbverse.balfourbeatty.net')) {
  type = "prod"

  domain = 'https://api.bbverse.balfourbeatty.net'

} else if (host_name.includes('bbverse.qa.balfourbeatty.net')) {
  type = "qa"

  domain = 'https://bbverse-api-qa.azurewebsites.net'

} else {
  // domain = 'http://yaagesh-dev.centralindia.cloudapp.azure.com:8000'
  // domain = 'http://sakthi-dev.centralindia.cloudapp.azure.com:8001'
  // domain = 'http://shweetha-dev.centralindia.cloudapp.azure.com:8002'
  // domain = 'https://bbverse-api-qa.azurewebsites.net'
  domain = 'http://localhost:8007'
  // domain = 'http://paul-dev.centralindia.cloudapp.azure.com:8003'
}

localStorage.setItem('api_domain', domain)

export function getApiDomain() {
  return domain
}

export function getEnvType() {
  return type
}

class ServiceClass {
  constructor() {
    let service = null;

    service = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.state = {
      statusCode: null
    };
  }


  get(path, options) { 

    return this.service
      .get(domain + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  patch(path, options) {

    return this.service
      .request({
        method: "PATCH",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  update(path, options) {

    return this.service
      .request({
        method: "UPDATE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  post(path, options) {

    return this.service
      .request({
        method: "POST",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  put(path, options) {

    return this.service
      .request({
        method: "PUT",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }

  delete(path, options) {

    return this.service
      .request({
        method: "DELETE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  }


}

const Service = new ServiceClass()

export default Service;